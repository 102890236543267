.containerMaxWidth {
  max-width: 1200px;
}

.designStudio__formGroup {
  font-size: 14px;
}
.designStudio__formGroup > select {
  font-size: 14px;
}

.fontSettings,
.fontSettings select,
.fontSettings textarea,
.fontSettings input {
  font-size: 14px !important;
  color: #464646;
  font-weight: 400;
}

.redButton {
  background-color: #ffecee;
  border: none;
  color: #d0011c !important;
  display: flex;
  align-items: center;
  border-radius: 16px;
  padding: 6px 16px;
  font-size: 14px;
  transition: 0.15s ease;
}

.redButton:hover {
  background-color: #d0011c;
  color: white !important;
}

.grayButton {
  background-color: #faf9f7;
  border: none;
  color: #997b40 !important;
  display: flex;
  align-items: center;
  border-radius: 16px;
  padding: 6px 16px;
  font-size: 14px;
  transition: 0.15s ease;
}

.backToProjectsButton {
  padding: 6px 16px 6px 12px !important;
}

.grayButton:hover {
  background-color: #997b40;
  color: white !important;
}
.backToProjectsButton > img {
  padding-right: 8px;
}

.footerActionBar {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px;
  position: absolute;
  bottom: 0;
  width: 94.5%;
  margin-left: -35px;
  box-shadow: 2px -2px 3px 0px #00000014;
  transition: all 0.5s ease;
  background-color: white;
}
.footerActionBar.success {
  background-color: #55a04b;
  color: white;
}

.footerActionBar.fail {
  background-color: #994140;
  color: white;
}

.footerActionBarButtonsContainer {
  margin-right: 100px;
}

.isMiniSidebar .footerActionBarButtonsContainer {
  margin-right: 0;
}

@media (max-width: 991px) {
  .footerActionBar {
    margin-left: -35px;
    width: 100%;
  }
  .footerActionBarButtonsContainer {
    margin-right: 0;
  }
}

.PropertySelect > div:hover {
  border-color: #dfd6c4;
}

.designStudioConfirmModal .modal-content {
  border-radius: 0 !important;
}

.lookupTableWrapper {
  height: 250px;
  overflow-y: scroll;
  border: 1px solid #dfd6c4;
  border-radius: 4px;
  scrollbar-width: none;
}

.lookupTable > tbody {
  border: 1px solid transparent !important;
  border-left: none !important;
}

.lookupTable > tbody > tr td {
  color: #5d5d5d !important;
  height: 38px;
  border-width: 0;
}

.lookupTable > tbody > tr:hover {
  background-color: #ebe5d9 !important;
}
.lookupModalInput:focus,
.lookupModalInput:active,
.lookupModalInput:hover {
  border-color: #997b40 !important;
}
